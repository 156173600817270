export const WEBSITE_URL = process.env.VUE_APP_WEBSITE_URL;
export const WEBSITE_PUBLIC_URL = process.env.VUE_APP_WEBSITE_PUBLIC_URL;
export const RECHENKERN_URL = process.env.VUE_APP_RECHENKERN_URL;
export const PUSHER_KEY = '1047a486123a2b9c5591';
export const PUSHER_CLUSTER = 'eu';
export const zipApiUid = 'tkhorukdev@gmail.com';
export const zipApiKey = '043f7774-d6f6-419c-8c03-06a09c74127b';

export const AXIOS_DEFAULT = {
  baseURL: WEBSITE_URL,
  publicURL: WEBSITE_PUBLIC_URL,
  PUSHER_KEY,
  PUSHER_CLUSTER,
};

export const CASE_STATUS = {
  DRAFT: 0,
  OFFER: 1,
  OFFER_REQUEST: 11,
  OFFER_REQUEST_READY: 12,
  SUGGESTION: 2,
  CONTRACT: 3,
  CONTRACT_NO_SIGNATURE: 4,
};
