import IWarenHandwerker from '@/model/IWarenHandwerker';
import IWarenHersteller from '@/model/IWarenHersteller';
import IAusstellungJahresvertrag from '@/model/IAusstellungJahresvertrag';
import IWerksverkehr from '@/model/IWerksverkehr';
import IMusterkollektion from '@/model/IMusterkollektion';
import {
  WEBSITE_URL,
  WEBSITE_PUBLIC_URL,
  RECHENKERN_URL,
  zipApiUid,
  zipApiKey
} from '@/constant/constants';
import { api, downloadApi } from './instance';

export type ICalculate =
  | IWarenHandwerker
  | IWarenHersteller
  | IAusstellungJahresvertrag
  | IWerksverkehr
  | IMusterkollektion;

const priceCalculator = {
  fetch: (draw: number, search: string, length: number, start: number, letter: string, sortBy: string, sortType: string, idSearchValue: string, processNameSearchValue: string, userSearchValue: string) => api.get(`insurances?draw=${draw}&search=${search}&idSearchValue=${idSearchValue}&processNameSearchValue=${processNameSearchValue}&userSearchValue=${userSearchValue}&length=${length}&start=${start}&letter=${letter}&sortBy=${sortBy}&sortType=${sortType}`),
  calculate: (data: ICalculate, productId: string) => {
    const id = productId;
    return api.post(`/Calculation/CalculateObject?productId=${id}`, data, {
      baseURL: `${RECHENKERN_URL}/api`,
      headers: {
        common: {
          password: 'T1RtRjNXcHV6VWZiXjQjRW9CS3k=',
          'Content-Type': 'application/json',
        },
      },
    });
  },
  fetchSingle: (id: string) => api.get(`insurance/${id}`),
  insurance: (data: any) =>
    api.post('insurance?action=generate_pdf', data, {
      baseURL: WEBSITE_URL,
    }),
  draftInsurance: () =>
    api.get('insurance/draft', {
      baseURL: WEBSITE_URL,
    }),
  checkSignature: (id: any) => api.get(`insurance/${id}/check-signature`),
  sendEmail: (data: { email: string; price: string}) => api.post('send-email', data),
  sendProposalEmail: (data: { email: string; id: string; pdf: string; btn: string; status: number }) => api.post('send-proposal-email', data),
  // sendEmailToAdmin: (data: { id: string }) => api.post('send-email-to-admin', data),
  manuallyPriceUpdate: (data: { id: number; manuallyPrice: object; status: number}) => api.post('manually-price', data),
  secondProductManuallyPriceUpdate: (data: { id: number; manuallyPrice: object; status: number}) => api.post('second-product-manually-price', data),
  sigature: (id: string, data: { image: string }) => api.post(`insurance/${id}/signature`, data),
  contractNoSignature: (id: string, data: { image: string }) => api.post(`insurance/${id}/signature`, data),
  emailDigital: (data: { email: string; link: string; id: string }) => api.post('email/digital-sign', data),
  // generatePDF: (id: any) => downloadApi.get(`generate-pdf/${id}`),
  generatePDF: (id: any) => api.get(`generate-pdf/${id}`),
  connectToOldSystem: () => api.get('login-api-old-logistik-system'),
  generatePDFforUser: (id: any) => api.get(`generate-pdf-for-user/${id}`),
  getStatusInsurance: (id: any) => api.get(`get-status-insurance/${id}`),
  // exportToExcel: () => api.get('export_insurance'),
  exportToExcel: () => downloadApi.get('export_insurance'),
  getProducts: () => api.get('getProducts'),
  getPermissionProduct: (key: any) => api.get(`getPermissionProduct/${key}`),
  getUserAPIData: () => api.get('getUserAPIData'),
  sendShortXML: (id: any) => api.get(`sendShortXML/${id}`),
  checkZipCode: (zipCode: string) => api.get(`/pickuppoint/api/postalCode/DE/getCityAndType/${zipCode}.json`, {
    baseURL: 'https://api.bring.com',
    headers: {
      common: {
        'X-MyBring-API-Uid': zipApiUid,
        'X-MyBring-API-Key': zipApiKey,
        'X-Bring-Client-URL': WEBSITE_PUBLIC_URL,
      },
    },
  })
};

export default priceCalculator;
