import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/views/home.vue';
import priceCalculator from '@/service/price-calculator';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/luggage-insurance/:id?',
    component: () => import(/* webpackChunkName: "index" */ '../views/insurance-forms/index.vue'),
    meta: {
      title: 'Reisegepäckversicherung',
      key: 'LUGGAGE_INSURANCE',
    },
    children: [
      {
        name: 'luggage-insurance',
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "luggage-insurance" */ '../views/insurance-forms/luggage-insurance.vue'
          ),
      },
      {
        name: 'luggage-insurance-price-card',
        path: 'price-card',
        component: () =>
          import(/* webpackChunkName: "price-card" */ '../views/insurance-forms/price-card.vue'),
      },
      {
        name: 'luggage-insurance-proposal-policy',
        path: 'proposal-policy',
        component: () =>
          import(
            /* webpackChunkName: "proposal-policy" */ '../views/insurance-forms/proposal-policy.vue'
          ),
      },
      {
        name: 'luggage-insurance-customer-general',
        path: 'customer-general',
        component: () =>
          import(
            /* webpackChunkName: "customer-general" */ '../views/insurance-forms/customer-general.vue'
          ),
      },
      {
        name: 'luggage-insurance-customer-data',
        path: 'customer-data',
        component: () =>
          import(
            /* webpackChunkName: "customer-data" */ '../views/insurance-forms/customer-data.vue'
          ),
      },
      {
        name: 'luggage-insurance-conditions-files',
        path: 'conditions',
        component: () => import(/* webpackChunkName: "contact" */ '../views/insurance-forms/conditions-files.vue'),
      },
    ]
  },
  {
    path: '/sample-collection-insurance/:id?',
    component: () => import(/* webpackChunkName: "index" */ '../views/insurance-forms/index.vue'),
    meta: {
      title: 'Musterkollektions-Versicherung'
    },
    children: [
      {
        name: 'sample-collection-insurance',
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "luggage-insurance" */ '../views/insurance-forms/sample-collection.vue'
          ),
      },
      {
        name: 'sample-collection-insurance-price-card',
        path: 'price-card',
        component: () =>
          import(/* webpackChunkName: "price-card" */ '../views/insurance-forms/price-card.vue'),
      },
      {
        name: 'sample-collection-insurance-conditions-files',
        path: 'conditions',
        component: () => import(/* webpackChunkName: "contact" */ '../views/insurance-forms/conditions-files.vue'),
      },
      {
        name: 'sample-collection-insurance-proposal-policy',
        path: 'proposal-policy',
        component: () =>
          import(
            /* webpackChunkName: "proposal-policy" */ '../views/insurance-forms/proposal-policy.vue'
          ),
      },
      {
        name: 'sample-collection-insurance-customer-general',
        path: 'customer-general',
        component: () =>
          import(
            /* webpackChunkName: "customer-general" */ '../views/insurance-forms/customer-general.vue'
          ),
      },
      {
        name: 'sample-collection-insurance-customer-data',
        path: 'customer-data',
        component: () =>
          import(
            /* webpackChunkName: "customer-data" */ '../views/insurance-forms/customer-data.vue'
          ),
      },
    ]
  },
  {
    path: '/fairground-insurance/:id?',
    component: () => import(/* webpackChunkName: "index" */ '../views/insurance-forms/index.vue'),
    meta: {
      title: 'Schaustellerversicherung'
    },
    children: [
      {
        name: 'fairground-insurance',
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "fairground-insurance" */ '../views/insurance-forms/fairground-insurance.vue'
          ),
      },
      {
        name: 'fairground-insurance-price-card',
        path: 'price-card',
        component: () =>
          import(/* webpackChunkName: "price-card" */ '../views/insurance-forms/price-card.vue'),
      },
      {
        name: 'fairground-insurance-proposal-policy',
        path: 'proposal-policy',
        component: () =>
          import(
            /* webpackChunkName: "proposal-policy" */ '../views/insurance-forms/proposal-policy.vue'
          ),
      },
      {
        name: 'fairground-insurance-customer-general',
        path: 'customer-general',
        component: () =>
          import(
            /* webpackChunkName: "customer-general" */ '../views/insurance-forms/customer-general.vue'
          ),
      },
      {
        name: 'fairground-insurance-customer-data',
        path: 'customer-data',
        component: () =>
          import(
            /* webpackChunkName: "customer-data" */ '../views/insurance-forms/customer-data.vue'
          ),
      },
      {
        name: 'fairground-insurance-conditions-files',
        path: 'conditions',
        component: () => import(/* webpackChunkName: "contact" */ '../views/insurance-forms/conditions-files.vue'),
      },
    ]
  },
  {
    path: '/transport-insurance/:id?',
    component: () => import(/* webpackChunkName: "index" */ '../views/insurance-forms/index.vue'),
    meta: {
      title: 'Transport Versicherung 4.0'
    },
    children: [
      {
        name: 'transport-insurance-proposal-policy',
        path: 'proposal-policy',
        component: () =>
          import(
            /* webpackChunkName: "proposal-policy" */ '../views/insurance-forms/proposal-policy.vue'
          ),
      },
      {
        name: 'transport-insurance-customer-general',
        path: 'customer-general',
        component: () =>
          import(
            /* webpackChunkName: "customer-general" */ '../views/insurance-forms/customer-general.vue'
          ),
      },
      {
        name: 'transport-insurance-customer-data',
        path: 'customer-data',
        component: () =>
          import(
            /* webpackChunkName: "customer-data" */ '../views/insurance-forms/customer-data.vue'
          ),
      },
      {
        name: 'transport-insurance-price-card',
        path: 'price-card',
        component: () =>
          import(/* webpackChunkName: "price-card" */ '../views/insurance-forms/price-card.vue'),
      },
      {
        name: 'own-goods',
        path: 'own-goods',
        component: () =>
          import(/* webpackChunkName: "own-goods" */ '../views/insurance-forms/own-goods.vue'),
      },
      {
        name: 'transport-insurance-agent-info',
        path: 'agent-info',
        component: () =>
          import(/* webpackChunkName: "agent-info" */ '../views/insurance-forms/agent-info.vue'),
      },
      {
        name: 'transport-insurance-conditions-files',
        path: 'conditions',
        component: () => import(/* webpackChunkName: "contact" */ '../views/insurance-forms/conditions-files.vue'),
      },
      {
        name: 'goods-insurance',
        path: 'goods-insurance',
        component: () =>
          import(
            /* webpackChunkName: "goods-insurance" */ '../views/insurance-forms/goods-insurance.vue'
          ),
      },
      {
        name: 'transport-insurance',
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "transport-insurance" */ '../views/insurance-forms/transport-insurance.vue'
          ),
      },
    ],
  },

  {
    path: '/exhibition-insurance/:id?',
    component: () => import(/* webpackChunkName: "index" */ '../views/insurance-forms/index.vue'),
    meta: {
      title: 'Ausstellungsversicherung'
    },
    children: [
      {
        name: 'exhibition-insurance',
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "luggage-insurance" */ '../views/insurance-forms/exhibition-insurance.vue'
          ),
      },
      {
        name: 'exhibition-insurance-price-card',
        path: 'price-card',
        component: () =>
          import(/* webpackChunkName: "price-card" */ '../views/insurance-forms/price-card.vue'),
      },
      {
        name: 'exhibition-insurance-proposal-policy',
        path: 'proposal-policy',
        component: () =>
          import(
            /* webpackChunkName: "proposal-policy" */ '../views/insurance-forms/proposal-policy.vue'
          ),
      },
      {
        name: 'exhibition-insurance-customer-general',
        path: 'customer-general',
        component: () =>
          import(
            /* webpackChunkName: "customer-general" */ '../views/insurance-forms/customer-general.vue'
          ),
      },
      {
        name: 'exhibition-insurance-customer-data',
        path: 'customer-data',
        component: () =>
          import(
            /* webpackChunkName: "customer-data" */ '../views/insurance-forms/customer-data.vue'
          ),
      },
      {
        name: 'exhibition-insurance-conditions-files',
        path: 'conditions',
        component: () => import(/* webpackChunkName: "contact" */ '../views/insurance-forms/conditions-files.vue'),
      },
      {
        name: 'short-term-insurance',
        path: 'short-term-insurance',
        component: () =>
          import(
            /* webpackChunkName: "term-insurance" */ '../views/insurance-forms/short-term-insurance.vue'
          ),
      },
      {
        name: 'annual-insurance',
        path: 'annual-insurance',
        component: () =>
          import(
            /* webpackChunkName: "annual-insurance" */ '../views/insurance-forms/annual-insurance.vue'
          ),
      },
    ]
  },
  {
    path: '/asset-insurance/:id?',
    component: () => import(/* webpackChunkName: "index" */ '../views/insurance-forms/index.vue'),
    meta: {
      title: 'Valorenversicherung'
    },
    children: [
      {
        name: 'asset-insurance',
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "luggage-insurance" */ '../views/insurance-forms/asset-insurance.vue'
          ),
      },
      {
        name: 'valor-insurance-compact',
        path: 'valor-insurance-compact',
        component: () =>
          import(
            /* webpackChunkName: "luggage-insurance" */ '../views/insurance-forms/valor-insurance-compact.vue'
          ),
      },
      {
        name: 'asset-insurance-price-card',
        path: 'price-card',
        component: () =>
          import(/* webpackChunkName: "price-card" */ '../views/insurance-forms/price-card.vue'),
      },
      {
        name: 'asset-insurance-proposal-policy',
        path: 'proposal-policy',
        component: () =>
          import(
            /* webpackChunkName: "proposal-policy" */ '../views/insurance-forms/proposal-policy.vue'
          ),
      },
      {
        name: 'asset-insurance-customer-general',
        path: 'customer-general',
        component: () =>
          import(
            /* webpackChunkName: "customer-general" */ '../views/insurance-forms/customer-general.vue'
          ),
      },
      {
        name: 'asset-insurance-customer-data',
        path: 'customer-data',
        component: () =>
          import(
            /* webpackChunkName: "customer-data" */ '../views/insurance-forms/customer-data.vue'
          ),
      },
      {
        name: 'asset-insurance-conditions-files',
        path: 'conditions',
        component: () => import(/* webpackChunkName: "contact" */ '../views/insurance-forms/conditions-files.vue'),
      },
    ]
  },
  /* market concept insurance */
  {
    path: '/market-concept-insurance/:id?',
    component: () => import(/* webpackChunkName: "index" */ '../views/insurance-forms/index.vue'),
    meta: {
      title: 'Marktkonzept-Versicherung'
    },
    children: [
      {
        name: 'market-concept-insurance',
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "luggage-insurance" */ '../views/insurance-forms/market-concept-insurance.vue'
          ),
      },
      {
        name: 'market-concept-insurance-price-card',
        path: 'price-card',
        component: () =>
          import(/* webpackChunkName: "price-card" */ '../views/insurance-forms/price-card.vue'),
      },
      {
        name: 'market-concept-insurance-proposal-policy',
        path: 'proposal-policy',
        component: () =>
          import(
            /* webpackChunkName: "proposal-policy" */ '../views/insurance-forms/proposal-policy.vue'
          ),
      },
      {
        name: 'market-concept-insurance-customer-general',
        path: 'customer-general',
        component: () =>
          import(
            /* webpackChunkName: "customer-general" */ '../views/insurance-forms/customer-general.vue'
          ),
      },
      {
        name: 'market-concept-insurance-customer-data',
        path: 'customer-data',
        component: () =>
          import(
            /* webpackChunkName: "customer-data" */ '../views/insurance-forms/customer-data.vue'
          ),
      },
      {
        name: 'market-concept-insurance-conditions-files',
        path: 'conditions',
        component: () => import(/* webpackChunkName: "contact" */ '../views/insurance-forms/conditions-files.vue'),
      },
    ]
  },
  /* end market concept insurance */
  /* logistics insurance */
  {
    path: '/logistics-insurance/:id?',
    component: () => import(/* webpackChunkName: "index" */ '../views/insurance-forms/index.vue'),
    meta: {
      title: 'Logistik Versicherung 4.0'
    },
    children: [
      {
        name: 'logistics-insurance',
        path: '',
        component: () =>
          import('../views/insurance-forms/logistics-insurance.vue'),
      },
      {
        name: 'road-haulier',
        path: 'road-haulier',
        component: () =>
          import(/* webpackChunkName: "road-haulier" */ '../views/insurance-forms/road-haulier.vue'),
      },
      {
        name: 'forwarding-activities',
        path: 'forwarding-activities',
        component: () =>
          import(/* webpackChunkName: "forwarding-activities" */ '../views/insurance-forms/forwarding-activities.vue'),
      },
      {
        name: 'watchman',
        path: 'watchman',
        component: () =>
          import('../views/insurance-forms/watchman.vue'),
      },
      {
        name: 'logistics-insurance-price-card',
        path: 'price-card',
        component: () =>
          import('../views/insurance-forms/price-card.vue'),
      },
      {
        name: 'logistics-insurance-proposal-policy',
        path: 'proposal-policy',
        component: () =>
          import('../views/insurance-forms/proposal-policy.vue'),
      },
      {
        name: 'logistics-insurance-customer-general',
        path: 'customer-general',
        component: () =>
          import('../views/insurance-forms/customer-general.vue'),
      },
      {
        name: 'logistics-insurance-customer-data',
        path: 'customer-data',
        component: () =>
          import('../views/insurance-forms/customer-data.vue'),
      },
      {
        name: 'logistics-insurance-conditions-files',
        path: 'conditions',
        component: () => import(/* webpackChunkName: "contact" */ '../views/insurance-forms/conditions-files.vue'),
      },
    ]
  },
  /* end Logistics insurance */


  {
    path: '/signature-pad/:id',
    component: () => import(/* webpackChunkName: "signature-pad" */ '../views/signature.vue'),
  },
  {
    path: '/products',
    name: 'products',
    component: () => import(/* webpackChunkName: "products" */ '../views/products.vue'),
  },
  {
    path: '/offers',
    name: 'offers',
    component: () => import(/* webpackChunkName: "offers"  */ '../views/offers.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/contact.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ x: 0, y: 0 }),
});


function getAccessProducts(key: string) {
  if (key === '') {
    return true;
  }
  return priceCalculator.getPermissionProduct(key);
}

function getAccessProductKey(name: string | null | undefined) {
  let key = '';
  switch (name) {
    case 'transport-insurance':
      key = 'OWN_GOODS_INSURANCE';
      break;
    case 'asset-insurance':
      key = 'VALOR_INSURANCE_COMPACT';
      break;
    case 'annual-insurance':
      key = 'ANNUAL_INSURANCE';
      break;
    case 'short-term-insurance':
      key = 'SHORT_TERM_INSURANCE';
      break;
    case 'sample-collection-insurance':
      key = 'SAMPLE_COLLECTION_INSURANCE';
      break;
    case 'fairground-insurance':
      key = 'FAIRGROUND_INSURANCE ';
      break;
    case 'luggage-insurance':
      key = 'LUGGAGE_INSURANCE';
      break;
    case 'logistics-insurance':
      key = 'LOGISTICS_INSURANCE';
      break;
    case 'exhibition-insurance':
      key = 'ANNUAL_INSURANCE';
      break;
    default:
      break;
  }
  return key;
}

router.beforeEach((to, from, next) => {
  const res = getAccessProducts(getAccessProductKey(to.name));
  if (typeof res !== 'boolean') {
    res.then((response) => {
      if (!response.data) {
        next({ name: 'products' });
      }
    });
  }
  if (!res) {
    next({ name: 'products' });
  } else next();
});

export default router;
