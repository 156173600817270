












import Vue from 'vue';
import priceCalculator from '@/service/price-calculator';
import AppHeader from './components/shared/app-header.vue';
import AppFooter from './components/shared/app-footer.vue';


export default Vue.extend({
  name: 'MainApp',
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      assesment: {
        operatingMode: '',
        remarks: '',
        processName: '',
        type: '',
        haveTransport: false,
        haveBussiness: false,
      },
      insuranceData: null,
      customerData: null,
      customerDetails: null,
      priceCardData: null,
      caseStatus: 0,
      key: '',
      ID: '',
      isLoggedIn: false,
    };
  },
  methods: {
    getDraftInsurance() {
      priceCalculator.draftInsurance().then((response) => {
        // eslint-disable-next-line @typescript-eslint/camelcase
        const { form_data, price_data, insurance_type, status } = response.data.data;

        // eslint-disable-next-line @typescript-eslint/camelcase
        const { insuranceData, assesment, customerData } = form_data;

        this.insuranceData = insuranceData;
        this.assesment = {
          ...assesment,
        };
        this.customerData = customerData ? customerData.user : null;
        this.customerDetails = customerData ? customerData.general : null;
        // eslint-disable-next-line @typescript-eslint/camelcase
        this.priceCardData = price_data;
        // eslint-disable-next-line @typescript-eslint/camelcase
        this.key = insurance_type;
        this.caseStatus = status;

        if (this.key === 'FAIRGROUND_INSURANCE') {
          this.assesment.remarks = insuranceData.remarks ? insuranceData.remarks : '';
          this.$router.push('/fairground-insurance');
        }
      });
    },
    onID(id: string) {
      this.ID = id;
    },
    getCustomerData(customerData: any) {
      this.customerData = customerData;
    },
    LoggedIn(status: boolean) {
      this.isLoggedIn = status;
    },
  },
  mounted() {
    // console.log(this.$store.state.userIsLoggin);
    // this.getDraftInsurance();
  },
});
