















































import Vue from 'vue';
import axios from 'axios';
import { WEBSITE_URL } from '@/constant/constants';
import { user } from '@/service/api';
import { login as handleAuth } from '@/helper/handle-auth';
import { detectIE } from '@/helper/detect-browser';
import errorMessage from '@/components/form/error-message.vue';


export default Vue.extend({
  name: 'login',
  components: {
    errorMessage,
  },
  props: ['LoggedIn'],
  data: () => ({
    username: '',
    password: '',
    showError: false,
  }),
  methods: {
    onSubmit() {
      if (!this.isIE) {
        const data = {
          email: this.username,
          password: this.password,
        };
        user.login(data).then((response) => {
          handleAuth(response.data.data);
          this.$emit('setIsLoggedIn', true);
          this.$router.push('/products');
        });
      } else {
        this.showError = true;
      }
    },
    onHaveGetToken(token: string, page: string) {
      const APIUrl = `${WEBSITE_URL}ams-login-data`;
      axios.get(APIUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        handleAuth(response.data.data);
        this.$emit('setIsLoggedIn', true);
        if (response.data.id) {
          this.redirectToRoute(response.data.id);
        } else {
          this.$router.push(`/${page}`);
        }
      });
    },
    GetInsurance(token: string) {
      const APIUrl = `${WEBSITE_URL}ams-get-insurance`;
      axios.get(APIUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        handleAuth(response.data.data);
        this.$emit('setIsLoggedIn', true);
        if (response.data.id) {
          this.redirectToRoute(response.data.id);
        }
        return response.data.id;
      });
      return false;
    },
    redirectToRoute(id: number) {
      const path = `transport-insurance/${id}`;
      this.$router.push({
        path,
      }).catch((err) => {
        throw new Error(`Problem handling something: ${err}.`);
      });
    },
  },
  computed: {
    isIE() {
      return detectIE();
    },
  },
  mounted() {
    // console.log(this.$route);
    /* console.log(`Is IE: ${this.isIE}`); */
    this.$emit('onId', '');
    this.$emit('getCustomerData', '');
    // this.$emit('LoggedIn', true);
    if (this.$route.query.token) {
      const result = this.GetInsurance(this.$route.query.token.toString()) && this.$route.query.page;
      if (!result) {
        this.onHaveGetToken(this.$route.query.token.toString(), this.$route.query.page.toString());
      }
    }
  },
});
